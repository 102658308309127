<template>
  <v-app>
    <v-dialog
      v-model="dialogLoading"
      hide-overlay
      v-if="loading"
      persistent
      width="300"
    >
      <v-card color="primary" dark>
        <v-card-text>
          <h2 class="white--text">Loading</h2>
          <v-progress-linear
            v-if="loading"
            indeterminate
            color="white"
            class="mb-0"
          ></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>

        <v-system-bar class="d-print-none pt-3" height="30" app lights-out>
      <v-toolbar-items class="text-right">
        <v-btn
          large
          rounded
          dark
          href="javascript:window.print()"
          class="title mr-2 rounded"
          color="info"
        >
          <v-icon class="mr-1" color="white" large>mdi-printer</v-icon>พิมพ์
        </v-btn>
      </v-toolbar-items>
    </v-system-bar>

    <div id="exportContent">
      <div class="page">
        <div align="center" class="div_j">
          <v-img
            max-height="30mm"
            max-width="25mm"
            src="krud.jpg"
          ></v-img>
          <div class="bold16">
           คำสั่งสำนักงานอาชีวศึกษาจังหวัด{{ user.province_name }}
          </div>        
          <div class="pa-2 bold16">   
            ที่................................................
          </div>
        </div>
        <div class="text-center text_j">
           เรื่อง แต่งตั้งคณะกรรมการรับเอกสารและตรวจสอบองค์ประกอบตัวชี้วัดและคะแนนในการประเมิน
        </div>
        <div class="text-center text_j">
           ตามองค์ประกอบการประเมินศักยภาพของผู้ประสงค์ขอย้าย ตำแหน่งครู (การย้ายกรณีปกติ) 
        </div>
        <div class="text-center text_j">
          สังกัดสำนักงานคณะกรรมการการอาชีวศึกษา
        </div>
       <div class="text-center text_j">
       _________________________________
       </div>

        <table class="text_j" border="0" width="100%" align="center">
          <tr>
            <td width="" class="regular16">
              <p>               
                ตามที่สำนักงานคณะกรรมการการอาชีวศึกษา โดยมติ อ.ก.ค.ศ. สังกัดสำนักงานคณะกรรมการการอาชีวศึกษา ครั้งที่ 17/2566 เมื่อวันที่ 30 พฤศจิกายน 2566 มีกำหนดการย้ายข้าราชการครูและบุคลากรทางการศึกษา ตำแหน่งครู ประจำปี ครั้งที่ {{ periods.period_vecprovince_times }}/{{ periods.period_vecprovince_year }} ตามนัยหนังสือสำนักงานคณะกรรมการการอาชีวศึกษา ที่ ศธ 0601/1313 ลงวันที่ 30 มกราคม 2567 นั้น                              
              </p>
            </td>
          </tr>
          <tr>
            <td width="" class="regular16">
              <p>
               
                เพื่อให้การดำเนินการย้ายข้ารากชารครูและบุคลากรทางการศึกษา ตำแหน่งครู สังกัดสำนักงานคณะกรรมการการอาชีวศึกษาเป็นไปด้วยความเรียบร้อย จึงแต่งตั้งคณะกรรมการรับเอกสารและตรวจสอบองค์ประกอบตัวชี้วัดและคะแนนในการประเมินตามองค์ประกอบการประเมินศักยภาพของผู้ประสงค์ขอย้าย ตำแหน่งครู (การย้ายกรณีปกติ) สังกัดสำนักงานคณะกรรมการการอาชีวศึกษา ประกอบด้วยคณะกรรมการ ดังต่อไปนี้        
              </p>
            </td>
          </tr>
        </table>
        <div class="bold16 text_j">
          รายชื่อคณะกรรมการ
        </div>
        <table class="text_j" width="100%" align="center">       
          <tr
            v-for="(item, index) in commiteevecpros"
            :key="item.commiteeVecProIdCard"
          >
          <td width="5%"></td>           
            <td width="30%" class="pa-1">
              {{ index + 1 }}.{{ item.title_s + item.frist_name }} {{ item.last_name }}
            </td>
            <td width="50%" class="pa-1">
          
              <span v-if="item.commiteeVecProPosition === '1'">
                ผู้อำนวยการสำนักงานอาชีวศึกษาจังหวัด{{ user.province_name }}</span
              >
              <span v-else>
     <span v-if="item.position_name==='ผู้อำนวยการวิทยาลัย'">ผู้อำนวยการ</span>
     <span v-else-if="item.position_name==='รองผู้อำนวยการวิทยาลัย'">รองผู้อำนวยการ</span>
     <span v-else>{{ item.position_name }}</span>{{ item.college_name }}
              </span>
             
            </td>
            <td width="" class="text-right">
              <span v-if="item.commiteeVecProPosition === '1'">
                ประธานกรรมการ</span
              >
              <span v-if="item.commiteeVecProPosition === '2'"> กรรมการ</span>
              <span v-if="item.commiteeVecProPosition === '3'"> กรรมการ</span>
              <span v-if="item.commiteeVecProPosition === '4'"> กรรมการ</span>
              <span v-if="item.commiteeVecProPosition === '5'"> กรรมการ</span>
              <span v-if="item.commiteeVecProPosition === '6'"> กรรมการ</span>
              <span v-if="item.commiteeVecProPosition === '7'"> เลขานุการ</span>
            </td>
           
          </tr>
        </table>
        <div class="bold16 text_j">
          ให้คณะกรรมการมีหน้าที่ดังนี้
        </div>
        <table class="text_j mt-3">
          <tr>
            <td width="" class="regular16">
              <p>
               
                1.รับเอกสารและตรวจสอบองค์ประกอบตัวชี้วัดและให้คะแนนประเมินตามองค์ประกอบการประเมินศักยภาพของผู้ประสงค์ขอย้าย ตำแหน่งครู (การย้ายกรณีปกติ) สังกัดสำนักงานคณะกรรมการการอาชีวศึกษา ขององค์ประกอบที่ 1 - 6       
              </p>
            </td>
          </tr>
          <tr>
            <td width="" class="regular16">
              <p>
               2.ปฏิบัติหน้าที่อื่น ตามที่สำนักงานคณะกรรมการการอาชีวศึกษามอบหมาย      
              </p>
            </td>
          </tr>
          <tr>
            <td width="" class="regular16">
              <p>
               ทั้งนี้ ตั้งแต่บัดนี้เป็นต้นไป   
              </p>
            </td>
          </tr>
        </table>

        <div class="text-center">
          สั่ง ณ วันที่..................................................................พ.ศ.2567
        </div>

        <br>
        <table class="text_j mt-3" width="100%">
          <tr>         
            <td width="" class="text-center">
              ลงชื่อ.......................................................................
            </td>
          </tr>
          <tr>           
            <td width="" class="text-center">
              ( {{ user.provincialinfo_director }} )
            </td>
          </tr>
          <tr>           
            <td width="" class="text-center">
              ผู้อำนวยการสำนักงานอาชีวศึกษาจังหวัด{{ user.province_name }}
            </td>
          </tr>
        </table>
      </div>
    </div>
  </v-app>
</template>

<script>
export default {
  name: "App",
  data: () => ({
    ApiKey: "HRvec2021",
    loading: true,
    dialogLoading: false,
    periods: [],
    commiteevecpros: [],
    user: []
  }),

  async mounted() {
    await this.sweetAlertLoading()
    await this.periodQuery();
    await this.commiteevecprosQueryAll();
    await this.userCheck();
    Swal.close();

  },

  methods: {
    async sweetAlertLoading() {
          Swal.fire({
        title: "Please Wait !",
        timerProgressBar: true,
        didOpen: () => {
          Swal.showLoading();
          
        },
        onBeforeOpen: () => {
          Swal.showLoading();
        },
        onAfterClose: () => {
          Swal.hideLoading();
        },
        allowOutsideClick: false,
        allowEscapeKey: false,
        allowEnterKey: false
      });
    },

    async userCheck() {
      let result = await this.$http.post("province.php", {
        ApiKey: this.ApiKey,
        province_ID: this.url_result
      });
      this.user = result.data;
    },

    async periodQuery() {
      let result_period;
      result_period = await this.$http.post("period_vecprovince.php", {
        ApiKey: this.ApiKey,
        period_vecprovince_enable: "1",
        period_vecprovince_type: "discuss_teacher"
      });
      this.periods = result_period.data;
      if (this.periods.period_vecprovince_enable != "1")
        this.$router.push("/vecprovince");
    },

    async commiteevecprosQueryAll() {
      let result = await this.$http.post("commiteevecpro.php", {
        ApiKey: this.ApiKey,
        commiteevecproviceID: this.url_result,
        commiteeVecProYear: this.periods.period_vecprovince_year,
        commiteeVecProTime: this.periods.period_vecprovince_times
      });
      this.commiteevecpros = result.data;
    },

    Export2Doc(element, filename) {
      var preHtml =
        "<html xmlns:o='urn:schemas-microsoft-com:office:office' xmlns:w='urn:schemas-microsoft-com:office:word' xmlns='http://www.w3.org/TR/REC-html40'><head><meta charset='utf-16'><title>Export HTML To Doc</title></head><body>";
      var postHtml = "</body></html>";
      var html =
        preHtml + document.getElementById(element).innerHTML + postHtml;

      var blob = new Blob(["\ufeff", html], {
        type: "application/msword"
      });

      // Specify link url
      var url =
        "data:application/vnd.ms-word;charset=utf-16," +
        encodeURIComponent(html);

      // Specify file name
      filename = filename ? filename + ".doc" : "document.doc";

      // Create download link element
      var downloadLink = document.createElement("a");

      document.body.appendChild(downloadLink);

      if (navigator.msSaveOrOpenBlob) {
        navigator.msSaveOrOpenBlob(blob, filename);
      } else {
        // Create a link to the file
        downloadLink.href = url;

        // Setting the file name
        downloadLink.download = filename;

        //triggering the function
        downloadLink.click();
      }

      document.body.removeChild(downloadLink);
    }
  },
  computed: {
    url_result() {
      let result = window.location.href;
      let split_s = result.split("/");
      return split_s[6];
    },
    date_today() {
      let today = new Date();
      return today;
    }
  },
  props: {
    source: String
  }
};
</script>
<style>
body {
  font-family: "TH SarabunIT๙", "TH SarabunPSK";
  font-size: 16px;
  font-weight: 250;
}

.u {
  border-bottom: 1px dotted #000;
  text-decoration: none;
}
u {
  border-bottom: 1px dotted #000;
  text-decoration: none;
}

* {
  font-family: "TH SarabunIT๙", "TH SarabunPSK";
  box-sizing: border-box;
  -moz-box-sizing: border-box;
}

.table,
.th,
.td {
  border-bottom: 0.5px solid black;
  border-top: 1px solid black;
  border-left: 1px solid black;
  border-right: 1px solid black;
  border-collapse: collapse;
}

.text_j {
  border-collapse: collapse;
  text-align: justify;
  text-justify: inter-word;
  line-height: 1;
}
.div_j {
  border-collapse: collapse;
  text-justify: inter-word;
  line-height: 1;
}
.text_l {
  border-collapse: collapse;
  text-align: left;
  text-justify: inter-word;
  line-height: 1;
}

tfoot tr:hover {
  background-color: initial;
}

tbody tr:hover {
  background-color: inherit;
}
p {
    text-indent: 50px;		
}
td,
th {
  font-family: "TH SarabunIT๙", "TH SarabunPSK";
}

tfoot td {
  font-family: "TH SarabunIT๙", "TH SarabunPSK";
  font-weight: bold;
}

#verticaltext {
  writing-mode: tb-rl;
  filter: flipv fliph;
  -webkit-transform: rotate(-90deg);
  white-space: nowrap;
  display: block;
  overflow: hidden;
  padding: 0;
}

.page {
  font-family: "TH SarabunIT๙", "TH SarabunPSK";
  font-size: 16pt;
  width: 21cm;
  min-height: 29.7cm;
  border: 1px #d3d3d3 solid;
  border-radius: 5px;
  background: white;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
  margin-top: 0cm;
  margin-right: auto;
  margin-bottom: 0cm;
  margin-left: auto;
  padding-top: 1cm;
  padding-right: 1cm;
  padding-bottom: 0.5cm;
  padding-left: 1cm;
}

.head {
  font-family: "TH SarabunIT๙", "TH SarabunPSK";
  font-size: 22pt;
  font-weight: bold;
  line-height: 1.5;
}

.regular14 {
  font-family: "TH SarabunIT๙", "TH SarabunPSK";
  font-size: 14pt;
}

.regular16 {
  font-family: "TH SarabunIT๙", "TH SarabunPSK";
  font-size: 16pt;
}

.bold16 {
  font-family: "TH SarabunIT๙", "TH SarabunPSK";
  font-size: 16pt;
  font-weight: bold;
}

.bold14 {
  font-family: "TH SarabunIT๙", "TH SarabunPSK";
  font-size: 14pt;
  font-weight: bold;
}
.blod12 {
  font-family: "TH SarabunIT๙", "TH SarabunPSK";
  font-size: 14pt;
  font-weight: bold;
}

.blackRegula10 {
  font-family: "TH SarabunIT๙", "TH SarabunPSK";
  font-size: 12pt;
}

.blackBold18 {
  font-family: "TH SarabunIT๙", "TH SarabunPSK";
  font-size: 18pt;
  font-weight: bold;
}

.largerCheckbox {
  width: 30px;
  height: 30px;
  color: blue;
}
.td_line {
  border-bottom: 1pt dotted black;
}
/*.subpage {
            height: 247mm;
          }*/

.BlackBold10 {
  font-weight: bold;
}

#content {
  display: table;
}

#pageFooter {
  display: table-footer-group;
}
.text_al {
  text-align: left;
  vertical-align: top;
}
#pageFooter:after {
  counter-increment: page;
  content: counter(page);
}
.tab1 {
  padding-left: 4em;
}
.textAlignVer {
  filter: flipv fliph;
  -webkit-transform: rotate(-90deg);
  -moz-transform: rotate(-90deg);
  transform: rotate(-90deg);
  position: relative;
  margin-bottom: 2px;
}
@media print {
  .page {
    border: initial;
    border-radius: initial;
    width: initial;
    min-height: initial;
    box-shadow: initial;
    background: initial;
    padding-top: 1cm;
    padding-right: 1cm;
    padding-bottom: 1cm;
    padding-left: 1.5cm;
    page-break-after: always;
  }
  .head {
    font-family: "TH SarabunIT๙", "TH SarabunPSK";
    font-size: 18pt;
    font-weight: bold;
  }

  .regular12 {
    font-family: "TH SarabunIT๙", "TH SarabunPSK";
    font-size: 16pt;
  }

  .regular16 {
    font-family: "TH SarabunIT๙", "TH SarabunPSK";
    font-size: 16pt;
  }

  .bold16 {
    font-family: "TH SarabunIT๙", "TH SarabunPSK";
    font-size: 16pt;
    font-weight: bold;
  }

  .blod12 {
    font-family: "TH SarabunIT๙", "TH SarabunPSK";
    font-size: 16pt;
    font-weight: bold;
  }

  .blackRegula10 {
    font-family: "TH SarabunIT๙", "TH SarabunPSK";
    font-size: 14pt;
  }

  .blackBold18 {
    font-family: "TH SarabunIT๙", "TH SarabunPSK";
    font-size: 18pt;
    font-weight: bold;
  }

  /* .head {
              font-size: 18px;
              font-weight: bold;
            }
            .regular12 {
              font-size: 12px;
            }
            .blod12 {
              font-size: 12px;
              font-weight: bold;
            }
            .blackRegula8 {
              font-size: 8px;
            } */
  .noprint {
    display: none;
  }

  .gap-10 {
    width: 100%;
    height: 10px;
  }
  .gap-20 {
    width: 100%;
    height: 20px;
  }
  .gap-30 {
    width: 100%;
    height: 30px;
  }
}
</style>
